import { 
    TextField, 
    Box, 
    Button,
    FormControlLabel,
    Checkbox 
}  from '@mui/material'
import { useCreateAccountForm } from './useCreateAccountForm'
import InstitutionForm from '../InstitutionForm/InstitutionForm'

import classes from './CreateAccountForm.module.css'

const CreateAccountForm = ({onSubmit}) => {

    const {
        emailValue,
        emailChangeHandler,
        emailBlurHandler,
        emailHasError,
        emailHelperText,
        passwordValue,
        passwordChangeHandler,
        passwordBlurHandler,
        passwordHasError,
        passwordHelperText,
        firstNameValue,
        firstNameHasError,
        firstNameChangeHandler,
        firstNameBlurHandler,
        lastNameValue,
        lastNameHasError,
        lastNameChangeHandler,
        lastNameBlurHandler,
        formIsValid,
        submitHandler,
        isInstitution,
        setIsInstitution,
        tokenResponse
    } = useCreateAccountForm()

    if (isInstitution && tokenResponse) {
        return (
            <InstitutionForm token={tokenResponse} />
        )
    }

    return (
        <Box component='form' onSubmit={onSubmit}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adresa de Email"
                name="email"
                autoComplete="email"
                value={emailValue}
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
                error={emailHasError}
                helperText={emailHelperText}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Parola"
                type="password"
                id="password"
                autoComplete="current-password"
                value={passwordValue}
                onChange={passwordChangeHandler}
                onBlur={passwordBlurHandler}
                error={passwordHasError}
                helperText={passwordHelperText}
            />
            <div className={classes.name}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="Nume"
                    name="name"
                    autoComplete="firstname"
                    value={firstNameValue}
                    onChange={firstNameChangeHandler}
                    onBlur={firstNameBlurHandler}
                    error={firstNameHasError}
                />
                <div className={classes.divider}/>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="lastname"
                    label="Prenume"
                    type="lastname"
                    id="lastname"
                    autoComplete="lastname"
                    value={lastNameValue}
                    onChange={lastNameChangeHandler}
                    onBlur={lastNameBlurHandler}
                    error={lastNameHasError}
                />
            </div>
            <FormControlLabel 
                control={<Checkbox checked={isInstitution} onChange={() => setIsInstitution(!isInstitution)} />}
                label='Reprezint institutie/persoana juridica'
            />
            <Button
                fullWidth
                variant="contained"
                disabled={!formIsValid}
                sx={{ mt: 3, mb: 2 }}
                onClick={submitHandler}
            >
               {'Creeaza cont'}
            </Button>
        </Box>
    )
}

export default CreateAccountForm