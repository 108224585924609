import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#45ba36',
            contrastText: '#fff'
        },
        light: {
            main: '#fff'
        }
    }
})

export default theme