import React from 'react'
import { Container,  Navbar, Nav, Image } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import logo from '../../Theme/Assets/logo-lectoform.png'
import classes from './Header.module.css'

const Header = (props) => {
    const getClass = (isActive) => {
        if (isActive) return classes.activeLink
        else return classes.link
    }

    return (
        <Navbar expand='lg' className={classes.navbar} >
            <Nav>
                <Navbar.Brand href='/'>
                    <Image fluid className={classes.logo} src={logo} alt='logo lectoform'/>
                </Navbar.Brand>
            </Nav>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <NavLink 
                        to="/cursuri" 
                        className={(navData) => getClass(navData.isActive)}
                    >Cursuri
                    </NavLink>
                    <NavLink 
                        to="/contul-meu" 
                        className={(navData) => getClass(navData.isActive)}
                    >Contul Meu
                    </NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header