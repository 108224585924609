import React from 'react';
import {
  TextField,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Row, Container, Dropdown } from 'react-bootstrap';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useClientForm } from './useClientForm';
import CustomCheckbox from '../Checkbox/Checkbox';
import InstitutionForm from '../InstitutionForm/InstitutionForm';

import classes from './ClientForm.module.css';

const ClientForm = ({ onSave, onCancel }) => {
  const {
    nameValue,
    nameIsValid,
    nameHasError,
    nameChangeHandler,
    nameBlurHandler,
    emailValue,
    emailIsValid,
    emailHasError,
    emailChangeHandler,
    emailBlurHandler,
    budgetInstitutionValue,
    budgetInstitutionIsValid,
    budgetInstitutionChangeHandler,
    budgetInstitutionBlurHandler,
    budgetInstitutionHasError,
    cifValue,
    cifChangeHandler,
    cifBlurHandler,
    townValue,
    townChangeHandler,
    townBlurHandler,
    countryValue,
    countryChangeHandler,
    countryBlurHandler,
    resetName,
    resetEmail,
    resetCif,
    resetTown,
    resetCountry,
    isPersoanaFizica,
    setIsPersoanaFizica,
    isPlatitorTva,
    setIsPlatitorTva,
    institutions,
    setSelectedInstitution,
    selectedInstitution,
    token,
    isInstitutionOpen,
    setIsInstitutionOpen,
    onCreateClient,
  } = useClientForm(onSave);

  const CustomToggle = React.forwardRef(
    ({ children, onClick }, ref) => (
      <Button
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        endIcon={<ArrowDropDownIcon />}
        className={classes.dropdownButton}
        variant="outlined"
      >
        {children}
      </Button>
    ),
  );

  return (
    <Box component="form" sx={{ mt: 1 }}>
      <div>
        <div className={classes.formRow}>
          <TextField
            label="Nume"
            required
            value={nameValue}
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            error={nameHasError}
            fullWidth
            helperText={
              nameHasError ? 'Numele este obligatoriu' : ''
            }
          />
          <div className={classes.divider} />
          <TextField
            label="Email"
            required
            value={emailValue}
            fullWidth
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            error={emailHasError}
            helperText={
              emailHasError ? 'Email-ul este invalid' : ''
            }
          />
          <div className={classes.divider} />
          <TextField
            label="CIF"
            value={cifValue}
            onChange={cifChangeHandler}
            onBlur={cifBlurHandler}
            fullWidth
          />
        </div>
        <div className={classes.formRow}>
          <TextField
            label="Buget institutie"
            value={budgetInstitutionValue}
            onChange={budgetInstitutionChangeHandler}
            onBlur={budgetInstitutionBlurHandler}
            fullWidth
          />
          <div className={classes.divider} />
          <TextField
            label="Oras"
            value={townValue}
            onChange={townChangeHandler}
            onBlur={townBlurHandler}
            fullWidth
          />
          <div className={classes.divider} />
          <TextField
            label="Tara"
            value={countryValue}
            onChange={countryChangeHandler}
            fullWidth
            onBlur={countryBlurHandler}
          />
        </div>

        <FormControlLabel
          control={
            <Checkbox
              checked={isPersoanaFizica}
              onChange={() =>
                setIsPersoanaFizica(!isPersoanaFizica)
              }
            />
          }
          label="Persoana Fizica"
        />
        {!isPersoanaFizica && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isPlatitorTva}
                onChange={() =>
                  setIsPlatitorTva(!isPlatitorTva)
                }
              />
            }
            label="Platitor TVA"
          />
        )}
        {!isPersoanaFizica && (
          <div className={classes.form}>
            <h5>Institutie</h5>
            {isInstitutionOpen ? (
              <InstitutionForm
                onCancel={() => setIsInstitutionOpen(false)}
                onSave={(institution) => {
                  setIsInstitutionOpen(false);
                  setSelectedInstitution(institution);
                }}
              />
            ) : (
              <div className={classes.row}>
                <Dropdown
                  className={classes.dropdownButton}
                >
                  <Dropdown.Toggle as={CustomToggle}>
                    {selectedInstitution
                      ? `${selectedInstitution?.nume}`
                      : 'Selecteaza Institutie'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {institutions?.map((item) => (
                      <Dropdown.Item
                        as="button"
                        key={item.id}
                        className={classes.dropdownItem}
                      >
                        <Button
                          onClick={() => {
                            setSelectedInstitution(item);
                          }}
                        >
                          {item?.nume}
                        </Button>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  onClick={() => setIsInstitutionOpen(true)}
                >
                  Adauga Institutie
                </Button>
              </div>
            )}
          </div>
        )}
        <Button
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={onCreateClient}
        >
          Salveaza Client
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 3, mb: 2 }}
          style={{ marginLeft: 10 }}
          onClick={onCancel}
        >
          Anuleaza
        </Button>
      </div>
    </Box>
  );
};

export default ClientForm;
