import axios from 'axios';
import { Config } from '../../Config/apiPath';

const courseApi = axios.create({
  baseURL: Config.API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const getAllCourses = async (page, params) => {
  try {
    const response = await courseApi.get(
      `cursuri/cursuri/`,
    );
    if (response.statusText === 'OK') {
      return { ok: true, data: response.data };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const getAllLocations = async () => {
  try {
    const response = await courseApi.get(
      'locatii/locatii/',
    );
    if (response.statusText === 'OK') {
      return { ok: true, data: response.data };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const getAllPeriods = async () => {
  try {
    const response = await courseApi.get(
      'locatii/perioade/',
    );
    if (response.statusText === 'OK') {
      return { ok: true, data: response.data };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const getCourseDetails = async (id) => {
  try {
    const response = await courseApi.get(
      `cursuri/cursuri/${id}/`,
    );
    if (response.statusText === 'OK') {
      return { ok: true, data: response.data };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const createCourseEnrolment = async (
  token,
  courseId,
  sessionId,
  clientId,
  bookingData,
  throughSeap,
  userId,
  packageType,
  createProforma,
) => {
  try {
    let bodyJSON = {};
    if (bookingData === null) {
      bodyJSON = JSON.stringify({
        cursant: userId,
        prin_seap: throughSeap,
        package_type: packageType,
        id_client: clientId,
        id_curs: courseId,
        id_sesiune: sessionId,
        creaza_proforma: false,
      });
    } else {
      bodyJSON = JSON.stringify({
        cursant: userId,
        prin_seap: throughSeap,
        package_type: packageType,
        id_client: clientId,
        id_curs: courseId,
        id_sesiune: sessionId,
        date_cazare: bookingData,
        creaza_proforma: false,
      });
    }

    //console.log('inscriere', bodyJSON);
    const response = await fetch(
      `${Config.API_BASE_URL}/date/inscriere-curs/`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Token ' + token,
        },
        body: bodyJSON,
      },
    );
    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const getEnrolledCourses = async (token) => {
  try {
    const response = await fetch(
      `${Config.API_BASE_URL}/date/inscriere-curs/`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
      },
    );
    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const uploadCourseContract = async (token, file, label) => {
  let formData = new FormData();
  formData.append(label, file, file.name);
  try {
    const response = await fetch(
      `${Config.API_BASE_URL}/date/inscriere-curs/`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: formData,
      },
    );
    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

export {
  getAllCourses,
  getCourseDetails,
  getAllLocations,
  getAllPeriods,
  createCourseEnrolment,
  getEnrolledCourses,
  uploadCourseContract,
};
