import React, { useState, useEffect } from 'react';
import { Box, Grid, Button } from '@mui/material';
import LoginForm from '../../Components/LoginForm/LoginForm';
import CreateAccountForm from '../../Components/CreateAccountForm.js/CreateAccountForm';
import { useAuth } from './useAuth';

import classes from './Auth.module.css';
//parola minim 8 caractere
// email, nume, prenume, parola
const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);

  const { token } = useAuth();

  useEffect(() => {
    console.log('auth token', token);
  }, [token]);

  return (
    <Grid item xs={12} sm={8} md={5} elevation={6}>
      <Box
        sx={{
          my: 6,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <LoginForm onSubmit={() => {}} />
      </Box>
    </Grid>
  );
};

export default Auth;
