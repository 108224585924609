import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import { userActions } from '../../Store/User/userSlice'
import { useDispatch } from 'react-redux'

export const useMyAccount = () => {

    const dispatch = useDispatch()
    const token = useSelector(state => state.user.token)
    const isInstitution = useSelector(state => state.user.isInstitution)
    const institution = useSelector(state => state.user.institution)

    const logout = () => {
        localStorage.removeItem('token')
        dispatch(userActions.authenticate({token: null}))
    }

    return {
        token,
        logout,
        isInstitution,
        institution
    }
} 