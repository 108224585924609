import React from 'react';
import { Row, Container } from 'react-bootstrap';
import { Button } from '@mui/material';
import { useMyAccount } from './useMyAccount';
import Auth from '../Auth/Auth';

const MyAccount = () => {
  const { token, logout } = useMyAccount();

  return (
    <Container>
      <Row className="justify-content-md-center text-center">
        {token ? (
          <Button onClick={logout}>Delogheaza-te</Button>
        ) : (
          <Auth />
        )}
      </Row>
    </Container>
  );
};

export default MyAccount;
