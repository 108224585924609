import {
  TextField,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useUserForm } from './useUserForm';
import UploadButton from '../UploadButton/UploadButton';
import { uploadUserDocument } from '../../services/User/UserService';
import CustomCheckbox from '../Checkbox/Checkbox';
import { Search } from '@mui/icons-material';

import classes from './UserForm.module.css';
import { Password } from '@mui/icons-material';

const UserForm = ({ goToBooking, clientEmail }) => {
  const {
    emailValue,
    emailChangeHandler,
    emailBlurHandler,
    emailHasError,
    emailHelperText,
    firstNameValue,
    setFirstNameValue,
    lastNameValue,
    setLastNameValue,
    cnpValue,
    setCnpValue,
    birthPlaceValue,
    setBirthPlaceValue,
    motherNameValue,
    setMotherNameValue,
    fatherNameValue,
    setFatherNameValue,
    schoolValue,
    setSchoolValue,
    addressValue,
    setAddressValue,
    hasCollegeDegree,
    setHasCollegeDegree,
    idFile,
    setIdFile,
    marriageFile,
    setMarriageFile,
    lastSchoolFile,
    setLastSchoolFile,
    birthCertificateFile,
    setBirthCertificateFile,
    token,
    wantsBooking,
    setWantsBooking,
    onCreateUser,
    getUserByEmail,
    handleSearchUser,
    throughSeap,
    setThroughSeap,
    phoneNumber,
    setPhoneNumber,
  } = useUserForm(goToBooking, clientEmail);

  return (
    <Box component="form" sx={{ mt: 1 }}>
      <div className={classes.box}>
        <h5>Date personale</h5>
        <div className={classes.name}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Adresa de Email"
            name="email"
            autoComplete="email"
            value={emailValue}
            autoFocus
            onChange={emailChangeHandler}
            onBlur={handleSearchUser}
            error={emailHasError}
            helperText={emailHelperText}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <IconButton
            //         aria-label="toggle password visibility"
            //         onClick={handleSearchUser}
            //         //onMouseDown={handleMouseDownPassword}
            //         edge="end"
            //       >
            //         {emailValue.length > 0 ? (
            //           <Search />
            //         ) : null}
            //       </IconButton>
            //     </InputAdornment>
            //   ),
            // }}
          />
          <div className={classes.divider} />
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstname"
            label="Nume"
            name="name"
            autoComplete="firstname"
            value={firstNameValue}
            onChange={(e) =>
              setFirstNameValue(e.target.value)
            }
            // onBlur={firstNameBlurHandler}
            // error={firstNameHasError}
          />
          <div className={classes.divider} />
          <TextField
            margin="normal"
            required
            fullWidth
            name="lastname"
            label="Prenume"
            type="lastname"
            id="lastname"
            autoComplete="lastname"
            value={lastNameValue}
            onChange={(e) =>
              setLastNameValue(e.target.value)
            }
            //onBlur={lastNameBlurHandler}
            //error={lastNameHasError}
          />
          <div className={classes.divider} />
          <TextField
            margin="normal"
            required
            fullWidth
            name="phoneNumber"
            label="Numar de Telefon"
            type="phoneNumber"
            id="phoneNumber"
            autoComplete="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            //onBlur={lastNameBlurHandler}
            //error={lastNameHasError}
          />
        </div>
        <div className={classes.name}>
          <TextField
            margin="normal"
            fullWidth
            required
            id="cnp"
            label="CNP"
            name="cnp"
            value={cnpValue}
            onChange={(e) => setCnpValue(e.target.value)}
            //onBlur={cnpBlurHandler}
          />
          <div className={classes.divider} />
          <TextField
            margin="normal"
            fullWidth
            required
            id="birthPlace"
            label="Locul Nasterii"
            name="birthPlace"
            value={birthPlaceValue}
            onChange={(e) =>
              setBirthPlaceValue(e.target.value)
            }
            //onBlur={birthPlaceBlurHandler}
          />
          <div className={classes.divider} />
          <TextField
            margin="normal"
            fullWidth
            required
            id="mothername"
            label="Prenumele mamei"
            name="mothername"
            value={motherNameValue}
            onChange={(e) =>
              setMotherNameValue(e.target.value)
            }
            //onBlur={motherNameBlurHandler}
          />
          <div className={classes.divider} />
          <TextField
            margin="normal"
            fullWidth
            required
            name="fathername"
            label="Prenumele tatalui"
            id="fathername"
            value={fatherNameValue}
            onChange={(e) =>
              setFatherNameValue(e.target.value)
            }
            //onBlur={fatherNameBlurHandler}
          />
        </div>
        <div className={classes.name}>
          <TextField
            margin="normal"
            fullWidth
            required
            name="address"
            label="Adresa"
            id="address"
            value={addressValue}
            onChange={(e) =>
              setAddressValue(e.target.value)
            }
            //onBlur={addressBlurHandler}
          />
          <div className={classes.divider} />
          <TextField
            margin="normal"
            fullWidth
            name="school"
            required
            label="Ultima scoala absolvita"
            id="school"
            value={schoolValue}
            onChange={(e) => setSchoolValue(e.target.value)}
            //onBlur={schoolBlurHandler}
          />
          <div className={classes.divider} />
          <FormControlLabel
            control={
              <Checkbox
                checked={hasCollegeDegree}
                onChange={() =>
                  setHasCollegeDegree(!hasCollegeDegree)
                }
              />
            }
            label="Studii Superioare"
            style={{
              fontSize: 20,
              color: '#212529',
              width: '100%',
              alignContent: 'center',
            }}
          />
        </div>
      </div>
      <div className={classes.box}>
        <h5>Documente</h5>
        <UploadButton
          label={'Copie Ultima Diploma: '}
          onChangeFile={(file) => {
            setLastSchoolFile(file);
          }}
        />
        <UploadButton
          label={'Copie Buletin: '}
          onChangeFile={(file) => {
            setIdFile(file);
          }}
        />

        <UploadButton
          label={'Copie Certificat Nastere: '}
          onChangeFile={(file) => {
            setBirthCertificateFile(file);
          }}
        />
        <UploadButton
          label={'Copie Certificat Casatorie: '}
          onChangeFile={(file) => {
            setMarriageFile(file);
          }}
        />
      </div>
      <CustomCheckbox
        label={'Vrea Cazare'}
        checked={wantsBooking}
        onChange={() => setWantsBooking(!wantsBooking)}
      />
      <CustomCheckbox
        label={'Prin SEAP'}
        checked={throughSeap}
        onChange={() => setThroughSeap(!throughSeap)}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          disabled={
            !emailValue ||
            !firstNameValue ||
            !lastNameValue ||
            !cnpValue ||
            !birthPlaceValue ||
            !motherNameValue ||
            !fatherNameValue ||
            !schoolValue ||
            !addressValue
          }
          style={{ alignSelf: 'center', marginTop: 20 }}
          onClick={() => {
            onCreateUser();
            if (idFile) {
              uploadUserDocument(
                token,
                idFile,
                'buletin',
                emailValue,
              );
            }
            if (lastSchoolFile) {
              uploadUserDocument(
                token,
                lastSchoolFile,
                'ultima_diploma',
                emailValue,
              );
            }
            if (birthCertificateFile) {
              uploadUserDocument(
                token,
                birthCertificateFile,
                'certificat_nastere',
                emailValue,
              );
            }
            if (marriageFile) {
              uploadUserDocument(
                token,
                marriageFile,
                'certificat_casatorie',
                emailValue,
              );
            }
          }}
          sx={{ mt: 3, mb: 2 }}
        >
          {!wantsBooking
            ? 'Salveaza si inscrie cursant'
            : 'Salveaza cursant si adauga cazare'}
        </Button>
      </div>
    </Box>
  );
};

export default UserForm;
