// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadButton_button__8NQOs {\n  display: flex;\n  flex-direction: row;\n  padding-left: 0;\n  margin-top: 16px;\n}\n\n.UploadButton_label__jkapW {\n  margin-top: auto;\n  margin-bottom: auto;\n\n  font-weight: 600;\n}\n\n.UploadButton_fileName__34LBN {\n  margin-top: auto;\n  margin-bottom: auto;\n  margin-right: 16px;\n  font-size: small;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/UploadButton/UploadButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;;EAEnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".button {\n  display: flex;\n  flex-direction: row;\n  padding-left: 0;\n  margin-top: 16px;\n}\n\n.label {\n  margin-top: auto;\n  margin-bottom: auto;\n\n  font-weight: 600;\n}\n\n.fileName {\n  margin-top: auto;\n  margin-bottom: auto;\n  margin-right: 16px;\n  font-size: small;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "UploadButton_button__8NQOs",
	"label": "UploadButton_label__jkapW",
	"fileName": "UploadButton_fileName__34LBN"
};
export default ___CSS_LOADER_EXPORT___;
