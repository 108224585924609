import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useInput from '../../Hooks/useInput';
import {
  isNotEmpty,
  isEmail,
} from '../../Utils/validations';
import {
  useGetInstitutionsQuery,
  useCreateClientMutation,
} from '../../Store/Api/apiSlice';

export const useClientForm = (onSave) => {
  const token = useSelector((state) => state.user.token);
  const { data, isLoading, error } =
    useGetInstitutionsQuery();

  const [isPersoanaFizica, setIsPersoanaFizica] =
    useState(false);
  const [isPlatitorTva, setIsPlatitorTva] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] =
    useState();
  const [isInstitutionOpen, setIsInstitutionOpen] =
    useState(false);

  const isNotEmpty = (value) => value.trim() !== '';
  const isEmail = (value) =>
    value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );

  const [createClient] = useCreateClientMutation();

  const onCreateClient = async () => {
    const createClientRes = await createClient({
      nume: nameValue,
      email: emailValue,
      cif: cifValue,
      oras: townValue,
      tara: countryValue,
      buget_institutie: budgetInstitutionValue || 0,
      persoana_fizica: isPersoanaFizica,
      platitor_tva: isPlatitorTva,
      institutie: selectedInstitution?.id || null,
    });
    if (createClientRes.data) {
      onSave(createClientRes.data);
    }
  };

  const {
    value: nameValue,
    isValid: nameIsValid,
    hasError: nameHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput(isNotEmpty);

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail);

  const {
    value: cifValue,
    isValid: cifIsValid,
    hasError: cifHasError,
    valueChangeHandler: cifChangeHandler,
    inputBlurHandler: cifBlurHandler,
    reset: resetCif,
  } = useInput();

  const {
    value: townValue,
    isValid: townIsValid,
    hasError: townHasError,
    valueChangeHandler: townChangeHandler,
    inputBlurHandler: townBlurHandler,
    reset: resetTown,
  } = useInput();

  const {
    value: countryValue,
    isValid: countryIsValid,
    hasError: countryHasError,
    valueChangeHandler: countryChangeHandler,
    inputBlurHandler: countryBlurHandler,
    reset: resetCountry,
  } = useInput();

  const {
    value: budgetInstitutionValue,
    isValid: budgetInstitutionIsValid,
    hasError: budgetInstitutionHasError,
    valueChangeHandler: budgetInstitutionChangeHandler,
    inputBlurHandler: budgetInstitutionBlurHandler,
    reset: resetBudgetInstitution,
  } = useInput();

  useEffect(() => {
    if (data) {
      setInstitutions(data);
    }
  }, [data]);

  return {
    nameValue,
    nameIsValid,
    nameHasError,
    nameChangeHandler,
    nameBlurHandler,
    emailValue,
    emailIsValid,
    emailHasError,
    emailChangeHandler,
    emailBlurHandler,
    budgetInstitutionValue,
    budgetInstitutionIsValid,
    budgetInstitutionChangeHandler,
    budgetInstitutionBlurHandler,
    budgetInstitutionHasError,
    cifValue,
    cifChangeHandler,
    cifBlurHandler,
    townValue,
    townChangeHandler,
    townBlurHandler,
    countryValue,
    countryChangeHandler,
    countryBlurHandler,
    resetName,
    resetEmail,
    resetCif,
    resetTown,
    resetCountry,
    isPersoanaFizica,
    setIsPersoanaFizica,
    isPlatitorTva,
    setIsPlatitorTva,
    institutions,
    setSelectedInstitution,
    selectedInstitution,
    token,
    isInstitutionOpen,
    setIsInstitutionOpen,
    onCreateClient,
  };
};
