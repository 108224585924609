// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AllCoursesPage_row__gALw0 {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    background-color: white;\n    z-index: 0;\n    height: 6rem;\n    flex: 1 1;\n    margin: 0;\n    border-bottom: 15px solid #45ba36;\n    border-top: 1px solid #45ba36;\n    padding: 3rem;\n}\n\n.AllCoursesPage_main__PR6h1 {\n    margin: 0;\n    padding-right: 0 !important;\n    padding-left: 0 !important;\n\n}\n\n.AllCoursesPage_coursesView__F-RQM {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-top: 1.5rem;\n}\n\n", "",{"version":3,"sources":["webpack://./src/Pages/Courses/AllCourses/AllCoursesPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,YAAY;IACZ,SAAO;IACP,SAAS;IACT,iCAAiC;IACjC,6BAA6B;IAC7B,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,2BAA2B;IAC3B,0BAA0B;;AAE9B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".row {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    background-color: white;\n    z-index: 0;\n    height: 6rem;\n    flex: 1;\n    margin: 0;\n    border-bottom: 15px solid #45ba36;\n    border-top: 1px solid #45ba36;\n    padding: 3rem;\n}\n\n.main {\n    margin: 0;\n    padding-right: 0 !important;\n    padding-left: 0 !important;\n\n}\n\n.coursesView {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-top: 1.5rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "AllCoursesPage_row__gALw0",
	"main": "AllCoursesPage_main__PR6h1",
	"coursesView": "AllCoursesPage_coursesView__F-RQM"
};
export default ___CSS_LOADER_EXPORT___;
