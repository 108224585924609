import React, {useState, useEffect} from 'react'
import useInput from '../../Hooks/useInput'
import { useDispatch } from 'react-redux'
import { authUser } from '../../Store/User/userActions'

export const useLoginForm = () => {

    const dispatch = useDispatch()

    const [emailHelperText, setEmailHelperText] = useState('')
    const [passwordHelperText, setPasswordHelperText] = useState('')

    const isEmail = (value) => value.toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )  
    const isPassword = (value) => value.length >= 8

    const {
        value: emailValue,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
    } = useInput(isEmail)

    const {
        value: passwordValue,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        reset: resetPassword
    } = useInput(isPassword)

    let formIsValid = false

    if (emailIsValid && passwordIsValid) {
        formIsValid = true
    }

    useEffect(() => {
        setEmailHelperText(emailHasError ? 'Adresa de email nu este valida' : '') 
    }, [emailHasError])

    useEffect(() => {
        setPasswordHelperText(passwordHasError ? 'Parola trebuie sa contina cel putin 8 caractere' : '')
    }, [passwordHasError])

    const submitHandler = () => {
        dispatch(authUser(emailValue, passwordValue, true, null, null))
    }

    return {
        emailValue,
        emailChangeHandler,
        emailBlurHandler,
        emailHasError,
        emailHelperText,
        passwordValue,
        passwordChangeHandler,
        passwordBlurHandler,
        passwordHasError,
        passwordHelperText,
        formIsValid,
        submitHandler
    }
}

