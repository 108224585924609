// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Checkbox_checkbox__X5Fgo {\n    font-size: 20;\n    color: '#505050'; \n    display: flex;\n    margin: 0;\n    padding: 0;\n    flex: 1 1;\n    width: '100%';\n}", "",{"version":3,"sources":["webpack://./src/Components/Checkbox/Checkbox.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,SAAS;IACT,UAAU;IACV,SAAO;IACP,aAAa;AACjB","sourcesContent":[".checkbox {\n    font-size: 20;\n    color: '#505050'; \n    display: flex;\n    margin: 0;\n    padding: 0;\n    flex: 1;\n    width: '100%';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "Checkbox_checkbox__X5Fgo"
};
export default ___CSS_LOADER_EXPORT___;
