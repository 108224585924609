import { createSlice } from '@reduxjs/toolkit';

const courseSlice = createSlice({
  name: 'course',
  initialState: {
    course: null,
    session: null,
    client: null,
    cursant: null,
    package: null,
    throughSeap: false,
  },
  reducers: {
    selectCourse(state, action) {
      state.course = action.payload.course;
    },
    selectSession(state, action) {
      state.session = action.payload.session;
    },
    selectClient(state, action) {
      state.client = action.payload.client;
    },
    selectCursant(state, action) {
      state.cursant = action.payload.cursant;
    },
    selectPackage(state, action) {
      state.package = action.payload.package;
    },
    selectThroughSeap(state, action) {
      state.throughSeap = action.payload.throughSeap;
    },
  },
});

export const courseActions = courseSlice.actions;
export default courseSlice;
