// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cazare_row__Ic-cC {\n    display: flex;\n    flex: 1 1;\n    flex-direction: row;\n    align-items: center;\n    margin-top: 1rem;\n}\n\n.Cazare_selectView__5EMqc {\n    width: 38rem;\n}\n\n.Cazare_main__YEZId {\n    margin: 0;\n    padding: 1rem !important;\n    border: 1px solid #45ba36;\n    border-radius: 8px;\n}\n\n.Cazare_checkbox__QXRli {\n    margin-left: 1rem;\n    padding-bottom: 0.5rem;\n}\n\n.Cazare_divider__brYkF {\n    width: 10px;\n}\n\n.Cazare_container__Drdsw {\n    display: flex;\n    flex-direction: column;\n    margin-top: 1.5rem;\n}", "",{"version":3,"sources":["webpack://./src/Pages/OfertaCazare/Cazare.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAO;IACP,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,wBAAwB;IACxB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":[".row {\n    display: flex;\n    flex: 1;\n    flex-direction: row;\n    align-items: center;\n    margin-top: 1rem;\n}\n\n.selectView {\n    width: 38rem;\n}\n\n.main {\n    margin: 0;\n    padding: 1rem !important;\n    border: 1px solid #45ba36;\n    border-radius: 8px;\n}\n\n.checkbox {\n    margin-left: 1rem;\n    padding-bottom: 0.5rem;\n}\n\n.divider {\n    width: 10px;\n}\n\n.container {\n    display: flex;\n    flex-direction: column;\n    margin-top: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "Cazare_row__Ic-cC",
	"selectView": "Cazare_selectView__5EMqc",
	"main": "Cazare_main__YEZId",
	"checkbox": "Cazare_checkbox__QXRli",
	"divider": "Cazare_divider__brYkF",
	"container": "Cazare_container__Drdsw"
};
export default ___CSS_LOADER_EXPORT___;
