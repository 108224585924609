import React, { useState, useEffect } from 'react';
import useInput from '../../Hooks/useInput';
import { useDispatch, useSelector } from 'react-redux';
import { createInstitution } from '../../services/User/UserService';
import { userActions } from '../../Store/User/userSlice';

export const useInstitutionForm = (onSave) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);

  const isEmail = (value) =>
    value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  const isNotEmpty = (value) => value.trim() !== '';

  const [emailHelperText, setEmailHelperText] =
    useState('');

  let formIsValid = false;

  const submitHandler = async () => {
    const createInstitutionRes = await createInstitution(
      token,
      JSON.stringify({
        nume: institutionNameValue,
        descriere: institutionDescriptionValue,
        cod_fiscal: fiscalCodeValue,
        email: emailValue,
        telefon: phoneValue,
        address: addressValue,
        banca: bankValue,
        iban: ibanValue,
        reprezentant_legal: legalRepresentativeValue,
      }),
    );
    if (createInstitutionRes.ok) {
      onSave(createInstitutionRes.data);
    }
  };

  const {
    value: institutionNameValue,
    isValid: institutionNameIsValid,
    hasError: institutionNameHasError,
    valueChangeHandler: institutionNameChangeHandler,
    inputBlurHandler: institutionNameBlurHandler,
    reset: resetInstitutionName,
  } = useInput(isNotEmpty);

  const {
    value: institutionDescriptionValue,
    isValid: institutionDescriptionIsValid,
    hasError: institutionDescriptionHasError,
    valueChangeHandler: institutionDescriptionChangeHandler,
    inputBlurHandler: institutionDescriptionBlurHandler,
    reset: resetInstitutionDescription,
  } = useInput();

  const {
    value: fiscalCodeValue,
    isValid: fiscalCodeIsValid,
    hasError: fiscalCodeHasError,
    valueChangeHandler: fiscalCodeChangeHandler,
    inputBlurHandler: fiscalCodeBlurHandler,
    reset: resetFiscalCode,
  } = useInput();

  const {
    value: addressValue,
    isValid: addressIsValid,
    hasError: addressHasError,
    valueChangeHandler: addressChangeHandler,
    inputBlurHandler: addressBlurHandler,
    reset: resetAddress,
  } = useInput();

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail);

  const {
    value: phoneValue,
    isValid: phoneIsValid,
    hasError: phoneHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    reset: resetPhone,
  } = useInput();

  const {
    value: bankValue,
    isValid: bankIsValid,
    hasError: bankHasError,
    valueChangeHandler: bankChangeHandler,
    inputBlurHandler: bankBlurHandler,
    reset: resetBank,
  } = useInput();

  const {
    value: ibanValue,
    isValid: ibanIsValid,
    hasError: ibanHasError,
    valueChangeHandler: ibanChangeHandler,
    inputBlurHandler: ibanBlurHandler,
    reset: resetIban,
  } = useInput();

  const {
    value: legalRepresentativeValue,
    isValid: legalRepresentativeIsValid,
    hasError: legalRepresentativeHasError,
    valueChangeHandler: legalRepresentativeChangeHandler,
    inputBlurHandler: legalRepresentativeBlurHandler,
    reset: resetLegalRepresentative,
  } = useInput(isNotEmpty);

  useEffect(() => {
    setEmailHelperText(
      emailHasError ? 'Adresa de email nu este valida' : '',
    );
  }, [emailHasError]);

  return {
    emailValue,
    emailChangeHandler,
    emailBlurHandler,
    emailHasError,
    emailHelperText,
    institutionNameValue,
    institutionNameHasError,
    institutionNameChangeHandler,
    institutionNameBlurHandler,
    institutionDescriptionValue,
    institutionDescriptionHasError,
    institutionDescriptionChangeHandler,
    institutionDescriptionBlurHandler,
    addressValue,
    addressHasError,
    addressChangeHandler,
    addressBlurHandler,
    phoneValue,
    phoneHasError,
    phoneChangeHandler,
    phoneBlurHandler,
    ibanValue,
    ibanHasError,
    ibanChangeHandler,
    ibanBlurHandler,
    fiscalCodeValue,
    fiscalCodeHasError,
    fiscalCodeChangeHandler,
    fiscalCodeBlurHandler,
    bankValue,
    bankHasError,
    bankChangeHandler,
    bankBlurHandler,
    legalRepresentativeValue,
    legalRepresentativeHasError,
    legalRepresentativeChangeHandler,
    legalRepresentativeBlurHandler,
    formIsValid,
    submitHandler,
  };
};
