import { 
    TextField, 
    Box, 
    Button 
}  from '@mui/material'
import { useLoginForm } from './useLoginForm'

const LoginForm = ({onSubmit}) => {

    const { 
        emailValue,
        emailChangeHandler,
        emailBlurHandler,
        emailHasError,
        emailHelperText,
        passwordValue,
        passwordChangeHandler,
        passwordBlurHandler,
        passwordHasError,
        passwordHelperText,
        formIsValid,
        submitHandler 
    } = useLoginForm()
    return (
        <Box component='form' onSubmit={onSubmit} >
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adresa de Email"
                name="email"
                autoComplete="email"
                value={emailValue}
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
                error={emailHasError}
                helperText={emailHelperText}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Parola"
                type="password"
                id="password"
                autoComplete="current-password"
                value={passwordValue}
                onChange={passwordChangeHandler}
                onBlur={passwordBlurHandler}
                error={passwordHasError}
                helperText={passwordHelperText}
            />
            <Button
                //type="submit"
                fullWidth
                variant="contained"
                disabled={!formIsValid}
                sx={{ mt: 3, mb: 2 }}
                onClick={submitHandler}
              >
               Intra in cont
            </Button>
        </Box>
    )
}

export default LoginForm