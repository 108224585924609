// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Auth_toggleLoginButton__KgtAb {\n  text-decoration: underline;\n  background-color: #fff;\n  color: #45ba36;\n  border: none;\n  margin-bottom: 20px;\n  align-self: center;\n}\n", "",{"version":3,"sources":["webpack://./src/Pages/Auth/Auth.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,sBAAsB;EACtB,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".toggleLoginButton {\n  text-decoration: underline;\n  background-color: #fff;\n  color: #45ba36;\n  border: none;\n  margin-bottom: 20px;\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleLoginButton": "Auth_toggleLoginButton__KgtAb"
};
export default ___CSS_LOADER_EXPORT___;
