import React from 'react'
import { Row } from 'react-bootstrap'

const NoResults = ({text}) => {
    return (
        <Row className="justify-content-md-center text-center">
            <h3 style={{paddingTop: 48}}>{text}</h3>
        </Row>
    )
}

export default NoResults