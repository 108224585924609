import React from 'react';
import { Row, Container } from 'react-bootstrap';
import { Button } from '@mui/material';
import { useEnrollCourse } from './useEnrollCourse';
import Auth from '../../Auth/Auth';
import UserForm from '../../../Components/UserForm/UserForm';

const EnrollCourse = () => {
  const {
    token,
    onUpdateUser,
    onUpdateUserWithBooking,
    goToBooking,
    clientEmail,
  } = useEnrollCourse();

  return (
    <Container>
      <h4 style={{ textAlign: 'center', margin: '1rem' }}>
        Cursant
      </h4>
      <div>
        <UserForm
          goToBooking={goToBooking}
          clientEmail={clientEmail}
        />
      </div>
    </Container>
  );
};

export default EnrollCourse;
