import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from '@mui/material';
import { Dropdown } from 'react-bootstrap';

import classes from './DropdownPicker.module.css';

const DropdownPicker = ({ values, onSelect, type }) => {
  const renderItem = (item) => {
    if (type === 'SESIUNE') {
      return `${item?.perioada?.data_start} - ${item?.perioada?.data_sfarsit}, ${item?.locatia?.oras}`;
    }
    if (type === 'PACHET') {
      return `${item.name}`;
    }
    if (type === 'HOTEL') {
      return `${item?.nume} , ${item?.locatie?.oras}`;
    }
    if (type === 'CAMERA') {
      return `${item?.nume} , ${item?.nr_adulti} Adulti, ${item?.nr_copii} Copii, ${item?.descriere || ''}`;
    }
    if (type === 'NR NOPTI') {
      return `${item?.perioada_cazare.data_start} - ${item?.perioada_cazare.data_sfarsit}, ${item?.pret} RON, ${item?.numar_nopti} Nopti, Cont De Masa: ${item?.cont_de_masa}`;
    }
    if (type === 'OPTIUNI CAMERA') {
      return `${item?.nume}, ${item?.descriere}, Pret/noapte ${item?.pret?.pret_noapte} RON, ${item?.pret?.perioada_cazare?.data_start} - ${item?.pret?.perioada_cazare?.data_sfarsit}`;
    }
  };

  const CustomToggle = React.forwardRef(
    ({ children, onClick }, ref) => (
      <Button
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        endIcon={<ArrowDropDownIcon />}
        className={classes.dropdownButton}
        variant="outlined"
      >
        {children}
      </Button>
    ),
  );

  return (
    // <div className={classes.dropdownLabel}>
    //   {label + renderItem(selectedValue)}
    <Dropdown className={classes.dropdownButton}>
      <Dropdown.Toggle as={CustomToggle}>
        {'Selecteaza  ' + type.toLowerCase()}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {values?.map((item, index) => (
          <Dropdown.Item as="button" key={item.id + index}>
            <Button
              onClick={() => onSelect(item)}
              className={classes.dropdownItem}
            >
              {renderItem(item)}
            </Button>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
    // </div>
  );
};

export default DropdownPicker;
