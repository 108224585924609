import React, { useEffect, useCallback } from 'react';
import './App.css';
import AppRouter from './Routing/AppRouter';
import { ThemeProvider } from '@mui/material/styles';
import theme from './Theme/theme';
import { useDispatch } from 'react-redux';
import { userActions } from './Store/User/userSlice';

function App() {
  const dispatch = useDispatch();

  const tryLogin = useCallback(async () => {
    let token = null;
    try {
      token = localStorage.getItem('token');
    } catch (err) {
      console.log('local storage error', err.message);
    }
    //console.log('local storage token', token);
    if (token) {
      dispatch(
        userActions.authenticate({
          token: token,
        }),
      );
    }
  }, [dispatch]);

  useEffect(() => {
    tryLogin();
  }, [dispatch, tryLogin]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </div>
  );
}

export default App;
