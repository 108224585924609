import React, { useState, useEffect } from 'react';
import useInput from '../../Hooks/useInput';
import { useDispatch, useSelector } from 'react-redux';
import { authUser } from '../../Store/User/userActions';
import { userActions } from '../../Store/User/userSlice';
import {
  createUser,
  login,
} from '../../services/User/UserService';

export const useCreateAccountForm = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.user.token);

  const [emailHelperText, setEmailHelperText] =
    useState('');
  const [passwordHelperText, setPasswordHelperText] =
    useState('');
  const [isInstitution, setIsInstitution] = useState(false);
  const [tokenResponse, setTokenResponse] = useState();

  const isEmail = (value) =>
    value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  const isPassword = (value) => value.length >= 8;
  const isNotEmpty = (value) => value.trim() !== '';

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail);

  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
  } = useInput(isPassword);

  const {
    value: firstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: resetFirstName,
  } = useInput(isNotEmpty);

  const {
    value: lastNameValue,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: resetLastName,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (
    emailIsValid &&
    passwordIsValid &&
    firstNameIsValid &&
    lastNameIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = async () => {
    if (!isInstitution) {
      dispatch(
        authUser(
          emailValue,
          passwordValue,
          false,
          lastNameValue,
          firstNameValue,
        ),
      );
    } else {
      const resCreateUser = await createUser(
        emailValue,
        passwordValue,
        lastNameValue,
        firstNameValue,
      );
      if (resCreateUser.ok) {
        const tokenRes = await login(
          emailValue,
          passwordValue,
        );
        if (tokenRes.ok) {
          setTokenResponse(tokenRes.data.token);
        }
      }
    }
  };

  useEffect(() => {
    setEmailHelperText(
      emailHasError ? 'Adresa de email nu este valida' : '',
    );
  }, [emailHasError]);

  useEffect(() => {
    setPasswordHelperText(
      passwordHasError
        ? 'Parola trebuie sa contina cel putin 8 caractere'
        : '',
    );
  }, [passwordHasError]);

  return {
    emailValue,
    emailChangeHandler,
    emailBlurHandler,
    emailHasError,
    emailHelperText,
    passwordValue,
    passwordChangeHandler,
    passwordBlurHandler,
    passwordHasError,
    passwordHelperText,
    firstNameValue,
    firstNameHasError,
    firstNameChangeHandler,
    firstNameBlurHandler,
    lastNameValue,
    lastNameHasError,
    lastNameChangeHandler,
    lastNameBlurHandler,
    formIsValid,
    submitHandler,
    isInstitution,
    setIsInstitution,
    tokenResponse,
  };
};
