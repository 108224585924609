import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useGetCoursesQuery,
  useGetCourseDetailsQuery,
} from '../../../Store/Api/apiSlice';
import { courseActions } from '../../../Store/Courses/courseSlice';

export const useAllCoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const [searchString, SetSearchString] = useState('');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    data,
    isLoading: isCoursesLoading,
    refetch,
  } = useGetCoursesQuery({
    search_nume: searchString,
  });

  useEffect(() => {
    if (data) {
      setCourses(data);
    }
  }, [data, searchString]);

  const handleCourseSelection = (course) => {
    dispatch(
      courseActions.selectCourse({
        course: course,
      }),
    );
    navigate(`/cursuri/${course.id}/sesiuni`, {
      courseId: course.id,
    });
  };

  useEffect(() => {
    refetch({
      search_nume: searchString,
    });
  }, [searchString, refetch]);

  return {
    courses,
    handleCourseSelection,
    isLoading: isCoursesLoading,
    searchString,
    SetSearchString,
    refetch,
  };
};
