import { createSlice } from "@reduxjs/toolkit"

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        token: null,
        institution: null,
        isInstitution: false
    },
    reducers: {
        authenticate(state, action) {
            state.token = action.payload.token
            state.user = action.payload.user
        },
        updateUser(state, action) {
            state.user = action.payload.user
        },
        updateInstitution(state, action) {
            state.institution = action.payload.institution
        },
        updateIsInstitution(state, action) {
            state.isInstitution = action.payload.isInstitution
        }
    }
})

export const userActions = userSlice.actions
export default userSlice