import { Config } from '../../Config/apiPath';

const getRoomOptions = async (
  token,
  hotelId,
  perioadaId,
) => {
  try {
    const response = await fetch(
      `${Config.API_BASE_URL}/locatii/optiuni-camera/?hotel=${hotelId}&id_perioada=${perioadaId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Token ' + token,
        },
      },
    );
    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const getRoomTypes = async (token, hotelId) => {
  try {
    const url = `${Config.API_BASE_URL}/locatii/camere/?hotel=${hotelId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token ' + token,
      },
    });
    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const getRoomPricesPerPeriod = async (
  token,
  roomTypeId,
  perioadaId,
) => {
  try {
    const url = `${Config.API_BASE_URL}/locatii/pret-perioada-camere/?camera=${roomTypeId}&id_perioada=${perioadaId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token ' + token,
      },
    });
    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const getRoomSessions = async (
  token,
  roomTypeId,
  perioadaId,
) => {
  try {
    const url = `${Config.API_BASE_URL}/locatii/camere-sesiuni/?camera=${roomTypeId}&id_perioada=${perioadaId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token ' + token,
      },
    });
    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const createBookingOffer = async (token, bookingOffer) => {
  try {
    const response = await fetch(
      `${Config.API_BASE_URL}/date/oferta-cazare/`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Token ' + token,
        },
        body: bookingOffer,
      },
    );
    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    } else {
      return { ok: false, errors: response.status };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

export {
  getRoomTypes,
  getRoomPricesPerPeriod,
  getRoomOptions,
  createBookingOffer,
  getRoomSessions,
};
