import React, { Fragment } from 'react';
import { useAllCoursesPage } from './useAllCoursesPage';
import CourseCard from '../../../Components/Course/Course';
import {
  ListGroup,
  Dropdown,
  Container,
} from 'react-bootstrap';
import Box from '@mui/material/Box';
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  TextField,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import Loading from '../../../Components/Loading/Loading';
import NoResults from '../../../Components/NoResults/NoResults';

import classes from './AllCoursesPage.module.css';

const AllCoursesPage = () => {
  const {
    courses,
    handleCourseSelection,
    isLoading,
    searchString,
    SetSearchString,
    handleSearch,
  } = useAllCoursesPage();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container fluid className={classes.main}>
      <div className={classes.row}>
        <TextField
          margin="normal"
          id="cautare-cursuri"
          label="Cauta Cursuri"
          name="cautare-cursuri"
          variant="outlined"
          style={{ width: '20rem' }}
          value={searchString}
          onChange={(e) => SetSearchString(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => SetSearchString('')}
                  edge="end"
                >
                  {searchString.length > 0 ? (
                    <Close />
                  ) : null}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {/* {courses.length ? (
        <div>
          <ListGroup>
            {courses?.map((item) => (
              <ListGroup.Item
                key={item.id}
                //style={{ cursor: 'pointer', width: '70%', alignSelf: 'center', margin: '12px'}}
              >
                <CourseCard
                  course={item}
                  onClick={handleCourseSelection}
                />
              </ListGroup.Item>
              // </Link>
            ))}
          </ListGroup>
        </div>
      ) : (
        <NoResults text={'Nu s-a gasit niciun curs.'} />
      )} */}

      <ListGroup className={classes.coursesView}>
        {courses?.map((item) => (
          <ListGroup.Item
            key={item.id}
            style={{
              cursor: 'pointer',
              width: '70%',
              alignSelf: 'center',
              border: 0,
            }}
          >
            <CourseCard
              course={item}
              onClick={handleCourseSelection}
            />
          </ListGroup.Item>
          // </Link>
        ))}
      </ListGroup>
    </Container>
  );
};

export default AllCoursesPage;
