import { userActions } from './userSlice';
import {
  createUser,
  login,
  updateUser,
} from '../../services/User/UserService';

export const authUser = (
  email,
  password,
  isLogin,
  name,
  firstname,
) => {
  return async (dispatch) => {
    let tokenResponse;
    if (!isLogin) {
      const resCreateUser = await createUser(
        email,
        password,
        name,
        firstname,
      );
      if (resCreateUser.ok) {
        tokenResponse = await login(email, password);
      }
    } else {
      tokenResponse = await login(email, password);
    }
    if (tokenResponse?.ok) {
      localStorage.setItem(
        'token',
        tokenResponse.data.token,
      );
      dispatch(
        userActions.authenticate({
          token: tokenResponse.data.token,
        }),
      );
    }
  };
};

export const updateUserData = (token, user) => {
  return async (dispatch) => {
    const userData = await updateUser(token, user);
    if (userData.ok) {
      dispatch(
        userActions.updateUser({ user: userData.data }),
      );
    }
  };
};
