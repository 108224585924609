import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserData } from '../../../Store/User/userActions';
import { useParams, useNavigate } from 'react-router-dom';
import { createCourseEnrolment } from '../../../services/Courses/CoursesService';
import { useCreateUserMutation } from '../../../Store/Api/apiSlice';

export const useEnrollCourse = () => {
  const token = useSelector((state) => state.user.token);

  const params = useParams();
  const { cursId } = params;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const goToBooking = () => {
    //dispatch(updateUserData(token, newUserData))
    navigate(`/cursuri/${cursId}/cazare`);
  };

  const [createUser] = useCreateUserMutation();

  const onUpdateUserWithBooking = async (newUserData) => {
    dispatch(updateUserData(token, newUserData));
    const resEnrollCourse = await createCourseEnrolment(
      token,
      cursId,
      false,
      true,
      null,
    );
  };

  const courseState = useSelector((state) => state.course);

  return {
    token,
    goToBooking,
    onUpdateUserWithBooking,
    createUser,
    clientEmail: courseState.client?.email || '',
  };
};
