// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_logo__9YFPc {\n  width: 80%;\n}\n\n.Header_navbar__L0SmY {\n  border-bottom: 1px solid #e1e1e1;\n  display: flex;\n  flex-direction: row;\n}\n\n.Header_activeLink__6P6If {\n  color: #45ba36;\n  text-decoration: none;\n  margin-right: 30px;\n}\n\n.Header_link__Yluyw:hover,\n.Header_activeLink__6P6If:hover {\n  text-decoration: none;\n  color: #45ba36;\n}\n\n.Header_link__Yluyw {\n  color: #505050;\n  text-decoration: none;\n  margin-right: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,gCAAgC;EAChC,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;;EAEE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":[".logo {\n  width: 80%;\n}\n\n.navbar {\n  border-bottom: 1px solid #e1e1e1;\n  display: flex;\n  flex-direction: row;\n}\n\n.activeLink {\n  color: #45ba36;\n  text-decoration: none;\n  margin-right: 30px;\n}\n\n.link:hover,\n.activeLink:hover {\n  text-decoration: none;\n  color: #45ba36;\n}\n\n.link {\n  color: #505050;\n  text-decoration: none;\n  margin-right: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "Header_logo__9YFPc",
	"navbar": "Header_navbar__L0SmY",
	"activeLink": "Header_activeLink__6P6If",
	"link": "Header_link__Yluyw"
};
export default ___CSS_LOADER_EXPORT___;
