import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  createBookingOffer,
  getRoomTypes,
  getRoomPricesPerPeriod,
  getRoomOptions,
  getRoomSessions,
} from '../../services/Booking/BookingService';
import { useNavigate } from 'react-router-dom';
import { createCourseEnrolment } from '../../services/Courses/CoursesService';
import { useGetHotelsQuery } from '../../Store/Api/apiSlice';

export const useCazare = () => {
  const params = useParams();
  const { cursId } = params;

  const navigate = useNavigate();

  const selectedSession = useSelector(
    (state) => state.course.session,
  );
  const selectedCourse = useSelector(
    (state) => state.course.course,
  );
  const courseState = useSelector((state) => state.course);
  const token = useSelector((state) => state.user.token);

  const roomsRef = useRef();
  const optionsRef = useRef();

  const [selectedHotel, setSelectedHotel] = useState();
  const [selectedRoom, setSelectedRoom] = useState();
  const [hotels, setHotels] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [availableRoomsNumber, setAvailableRoomsNumber] =
    useState();
  const [roomSessionId, setRoomSessionId] = useState();
  const [mealExtra, setMealExtra] = useState();
  const [receptionPayment, setReceptionPayment] =
    useState();
  const [roomSessions, setRoomSessions] = useState([]);
  const [selectedRoomType, setSelectedRoomType] =
    useState();
  const [selectedRoomOptions, setSelectedRoomOptions] =
    useState([]);
  const [selectedRoomPrice, setSelectedRoomPrice] =
    useState();
  const [pricesRoomTypes, setPricesRoomTypes] = useState(
    [],
  );
  const [selectPriceRoomType, setSelectPriceRoomType] =
    useState();
  const [uponRequest, setUponRequest] = useState(false);
  const [createBill, setCreateBill] = useState(false);

  const { data: hotelsData, isLoading: hotelsLoading } =
    useGetHotelsQuery(selectedSession?.locatia?.id);

  useEffect(() => {
    if (hotelsData) {
      setHotels(hotelsData);
    }
  }, [hotelsData]);

  const onHandleSelectHotel = async (hotel) => {
    setSelectedHotel(hotel);
    setSelectedRoom(null);
    setSelectedRoomType(null);
    setSelectedRoomOptions([]);
    setSelectedRoomPrice(null);
    setAvailableRoomsNumber(null);
    const roomTypes = await getRoomTypes(token, hotel.id);
    if (roomTypes.ok) {
      setRoomTypes(roomTypes.data);
    }
  };

  const handleRoomTypeSelection = async (roomType) => {
    setSelectedRoomType(roomType);
    const options = await getRoomOptions(
      token,
      selectedHotel.id,
      selectedSession?.perioada?.id,
    );
    if (options.ok) {
      let optionsPrices = [];
      options.data.forEach(async (option) => {
        option.preturi_perioade.forEach(async (price) => {
          optionsPrices.push({
            id: option.id,
            nume: option.nume,
            descriere: option.descriere,
            pret: price,
          });
        });
      });
      setRoomOptions(optionsPrices);
    }
    const sessionsRes = await getRoomSessions(
      token,
      roomType?.id,
      selectedSession?.perioada?.id,
    );

    if (sessionsRes.ok) {
      setRoomSessionId(sessionsRes.data[0]?.id);
      setAvailableRoomsNumber(
        sessionsRes.data[0]?.camere_disponibile,
      );
    }
    const prices = await getRoomPricesPerPeriod(
      token,
      roomType?.id,
      selectedSession?.perioada?.id,
      //sessionsRes.data[0]?.sesiune,
    );
    if (prices.ok) {
      setPricesRoomTypes(prices.data);
    }
    //console.log('sessions', sessions.data);
  };

  //   const handleSessionRoomSelection = async (session) => {
  //     setSelectedRoomSession(session);
  //     const prices = await getRoomPricesPerPeriod(
  //       token,
  //       selectedRoomType.id,
  //       selectedSession?.sesiune?.id,
  //     );
  //     if (prices.ok) {
  //       setPricesRoomTypes(prices.data);
  //     }
  //   };

  const handleRoomOptionSelection = (option) => {
    const newOptions = [...selectedRoomOptions];
    if (newOptions.includes(option)) {
      newOptions.splice(newOptions.indexOf(option), 1);
    } else {
      newOptions.push(option);
    }
    setSelectedRoomOptions(newOptions);
  };

  const onSubmit = async () => {
    const booking = {
      camera: selectedRoomType.id,
      plati_receptie: Number(receptionPayment) || 0,
      supliment_cont_masa: Number(mealExtra) || 0,
      camere_sesiuni: roomSessionId,
      pret_perioada_camere: selectedRoomPrice.id,
      la_cerere: uponRequest,
      optiuni: selectedRoomOptions.map((option) => {
        return {
          optiune: option.id,
          pret_perioada_optiune: option.pret.id,
        };
      }),
    };
    console.log('booking', booking);
    const resCreateEnrollment = await createCourseEnrolment(
      token,
      selectedCourse.id,
      selectedSession.id,
      courseState.client.id,
      booking,
      courseState.course.throughSeap,
      courseState.cursant,
      courseState.package?.type,
      createBill,
    );
    if (resCreateEnrollment.ok) {
      alert('Inscriere realizata cu succes!');
      navigate('/cursuri');
    }
  };

  return {
    hotels,
    selectedHotel,
    setSelectedHotel,
    selectedRoom,
    setSelectedRoom,
    roomOptions,
    selectedRoomOptions,
    setSelectedRoomOptions,
    selectedCourse,
    onSubmit,
    roomsRef,
    roomTypes,
    selectedRoomType,
    setSelectedRoomType,
    optionsRef,
    pricesRoomTypes,
    selectPriceRoomType,
    setSelectPriceRoomType,
    onHandleSelectHotel,
    handleRoomTypeSelection,
    roomSessions,
    selectedRoomPrice,
    setSelectedRoomPrice,
    mealExtra,
    setMealExtra,
    receptionPayment,
    setReceptionPayment,
    handleRoomOptionSelection,
    availableRoomsNumber,
    uponRequest,
    setUponRequest,
    createBill,
    setCreateBill,
    selectedCourse,
    selectedSession,
    //handleSessionRoomSelection,
  };
};
