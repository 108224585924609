import React from 'react';
import { Row, Dropdown, Container } from 'react-bootstrap';
import { List, Button, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { Delete } from '@mui/icons-material';
import Select from 'react-select';

import { useCazare } from './useCazare';
import DropdownPicker from '../../Components/DropdownPicker/DropdownPicker';

import classes from './Cazare.module.css';
import CustomCheckbox from '../../Components/Checkbox/Checkbox';

const Cazare = () => {
  const {
    hotels,
    selectedHotel,
    setSelectedHotel,
    selectedRoom,
    setSelectedRoom,
    rooms,
    selectedSession,
    roomOptions,
    selectedRoomOptions,
    setSelectedRoomOptions,
    selectedCourse,
    onSubmit,
    roomsRef,
    optionsRef,
    roomTypes,
    selectedRoomType,
    setSelectedRoomType,
    pricesRoomTypes,
    selectPriceRoomType,
    setSelectPriceRoomType,
    onHandleSelectHotel,
    handleRoomTypeSelection,
    roomSessions,
    selectedRoomPrice,
    mealExtra,
    setMealExtra,
    receptionPayment,
    setReceptionPayment,
    setSelectedRoomPrice,
    handleRoomOptionSelection,
    availableRoomsNumber,
    uponRequest,
    setUponRequest,
    createBill,
    setCreateBill,
  } = useCazare();

  console.log('selectedRoomPrice', selectedRoomPrice);

  const { control } = useForm();

  return (
    <Container>
      <h6 className={classes.row}>
        {selectedCourse.nume},{' '}
        {selectedSession?.locatia?.oras},{' '}
        {selectedSession?.perioada?.data_start} -{' '}
        {selectedSession?.perioada?.data_sfarsit}
      </h6>
      <h5 style={{ marginTop: 20 }}>Cazare</h5>
      <div className={classes.main}>
        <div className={classes.row}>
          <TextField
            value={mealExtra}
            onChange={(e) => setMealExtra(e.target.value)}
            label="Supliment Cont de Masa"
          />
          <div className={classes.divider} />
          <TextField
            value={receptionPayment}
            onChange={(e) =>
              setReceptionPayment(e.target.value)
            }
            label="Plati Receptie"
          />
        </div>
        <div className={classes.row}>
          <div className={classes.selectView}>
            <label>Hotel</label>
            <Select
              value={selectedHotel}
              onChange={(item) => onHandleSelectHotel(item)}
              options={hotels.map((hotel) => ({
                ...hotel,
                label: `${hotel?.nume} , ${hotel?.locatie?.oras}`,
              }))}
            />
          </div>
          <div className={classes.divider} />
          <div className={classes.selectView}>
            <label>Camera</label>
            <Select
              defaultValue={selectedRoomType}
              onChange={(item) => {
                handleRoomTypeSelection(item);
              }}
              value={selectedRoomType}
              options={roomTypes.map((item) => ({
                value: item.id,
                label: `${item?.nume} , ${item?.nr_adulti} Adulti, ${item?.nr_copii} Copii, ${item?.descriere || ''}`,
                id: item.id,
              }))}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.selectView}>
            <label>Nr. Nopti</label>
            <Select
              defaultValue={selectedRoomPrice}
              onChange={setSelectedRoomPrice}
              value={selectedRoomPrice}
              options={pricesRoomTypes.map((item) => ({
                ...item,
                label: `${item?.perioada_cazare?.data_start} - ${item?.perioada_cazare.data_sfarsit}, ${item?.pret} RON, ${item?.numar_nopti} Nopti, Cont De Masa: ${item?.cont_de_masa}`,
              }))}
            />
          </div>
          <div className={classes.divider} />
          <div className={classes.row}>
            <h6
              style={{
                marginRight: '8px',
                marginTop: '12px',
              }}
            >
              Camere Disponibile: {availableRoomsNumber}
            </h6>
            {availableRoomsNumber === 0 && (
              <div className={classes.checkbox}>
                <CustomCheckbox
                  checked={uponRequest}
                  onChange={() =>
                    setUponRequest(!uponRequest)
                  }
                  label="La cerere"
                />
              </div>
            )}
          </div>
        </div>
        <div style={{ marginTop: '16px' }}>
          <label>Optiuni Camera:</label>
          <Select
            defaultValue={''}
            onChange={(item) =>
              setSelectedRoomOptions(item)
            }
            isSearchable
            value={selectedRoomOptions}
            isMulti={true}
            options={roomOptions.map((item) => ({
              label: `${item?.nume}, ${item?.descriere}, Pret/noapte ${item?.pret?.pret_noapte} RON, ${item?.pret?.perioada_cazare?.data_start} - ${item?.pret?.perioada_cazare?.data_sfarsit}`,
              id: item.id,
              pret: item.pret,
              value: item.id,
            }))}
          />
        </div>

        <div className={classes.container}>
          <div>
            <CustomCheckbox
              checked={createBill}
              onChange={() => setCreateBill(!createBill)}
              label="Creeaza Proforma"
            />
          </div>
        </div>
      </div>
      <Button
        variant="contained"
        onClick={onSubmit}
        disabled={
          !selectedRoomPrice ||
          !selectedRoomType ||
          !selectedHotel
        }
        style={{ marginTop: '20px' }}
      >
        Salveaza
      </Button>
    </Container>
  );
};

export default Cazare;
