import { configureStore } from "@reduxjs/toolkit"
import courseSlice from "./Courses/courseSlice"
import userSlice from "./User/userSlice"
import { apiSlice } from "./Api/apiSlice"

const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        course: courseSlice.reducer,
        [apiSlice.reducerPath]: apiSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware)
})

export default store