import React, { useState, Fragment } from 'react';
import { Button, InputBase } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Delete } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Container } from 'react-bootstrap';

import classes from './UploadButton.module.css';

const UploadButton = ({ label, onChangeFile }) => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');

  const handleInputChange = (event) => {
    event.preventDefault();
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
    onChangeFile(event.target.files[0]);
  };

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <Container className={classes.button}>
      <p className={classes.label}>{label + ' '}</p>
      <p className={classes.fileName}>{fileName}</p>
      <label>
        <Input
          required
          accept="*"
          id="contained-button-file"
          className={classes.input}
          onChange={handleInputChange}
          type="file"
        />
        <Button
          variant="outlined"
          component="span"
          endIcon={<AttachFileIcon />}
        >
          Alege Fisier
        </Button>
      </label>
      {file && (
        <Button
          variant="outlined"
          color="error"
          component="span"
          onClick={() => {
            setFile(null);
            setFileName('');
            onChangeFile(null);
          }}
          style={{ marginLeft: '0.5rem' }}
          endIcon={<Delete />}
        >
          Sterge Fisier
        </Button>
      )}
    </Container>
  );
};

export default UploadButton;
