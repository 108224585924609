// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SessionSelect_main__sjhL9 {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 1rem;\n}\n\n.SessionSelect_row__2xTFR {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    margin-top: 2rem;\n    margin-bottom: 2rem;\n}\n\n.SessionSelect_selectView__x3fal {\n    width: 24rem;\n}\n\n.SessionSelect_divider__ypX6P {\n    width: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/Pages/Courses/SessionSelect/SessionSelect.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".main {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 1rem;\n}\n\n.row {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    margin-top: 2rem;\n    margin-bottom: 2rem;\n}\n\n.selectView {\n    width: 24rem;\n}\n\n.divider {\n    width: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "SessionSelect_main__sjhL9",
	"row": "SessionSelect_row__2xTFR",
	"selectView": "SessionSelect_selectView__x3fal",
	"divider": "SessionSelect_divider__ypX6P"
};
export default ___CSS_LOADER_EXPORT___;
