// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InstitutionForm_name__m8tb0 {\n  display: flex;\n  flex-direction: row;\n}\n\n.InstitutionForm_divider__DTXoV {\n  width: 40px;\n}\n\n.InstitutionForm_form__f000r {\n  border-width: 0.5px;\n  border-color: #45ba36;\n  border-style: solid;\n  padding: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/Components/InstitutionForm/InstitutionForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".name {\n  display: flex;\n  flex-direction: row;\n}\n\n.divider {\n  width: 40px;\n}\n\n.form {\n  border-width: 0.5px;\n  border-color: #45ba36;\n  border-style: solid;\n  padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "InstitutionForm_name__m8tb0",
	"divider": "InstitutionForm_divider__DTXoV",
	"form": "InstitutionForm_form__f000r"
};
export default ___CSS_LOADER_EXPORT___;
