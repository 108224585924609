import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

import classes from './Checkbox.module.css';

const CustomCheckbox = ({ label, checked, onChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          style={{
            color: '#212529',
            alignContent: 'center',
          }}
        />
      }
      label={label}
      className={classes.checkbox}
    />
  );
};

export default CustomCheckbox;
