// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserForm_name__3qUvN {\n  display: flex;\n  flex-direction: row;\n}\n\n.UserForm_divider__RHUf1 {\n  width: 40px;\n}\n\n.UserForm_box__TYzvu {\n  border-width: 0.5px;\n  border-color: #45ba36;\n  border-style: solid;\n  padding: 1rem;\n  margin-bottom: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/UserForm/UserForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".name {\n  display: flex;\n  flex-direction: row;\n}\n\n.divider {\n  width: 40px;\n}\n\n.box {\n  border-width: 0.5px;\n  border-color: #45ba36;\n  border-style: solid;\n  padding: 1rem;\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "UserForm_name__3qUvN",
	"divider": "UserForm_divider__RHUf1",
	"box": "UserForm_box__TYzvu"
};
export default ___CSS_LOADER_EXPORT___;
