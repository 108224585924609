import React, { Fragment } from 'react';
import { useAllCoursesPage } from './useCourseDetailsPage';
import { Row, Container, Dropdown } from 'react-bootstrap';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Loading from '../../../Components/Loading/Loading';
import ClientForm from '../../../Components/ClientForm/ClientForm';

import classes from './CourseDetailsPage.module.css';

const CourseDetailsPage = ({ route, navigation }) => {
  const {
    course,
    cursId,
    clients,
    withCertificate,
    setWithCertificate,
    legalInstitution,
    setLegalInstitution,
    isLoading,
    selectedClient,
    isCreateClientOpen,
    setIsCreateClientOpen,
    isCreateInstOpen,
    setIsCreateInstOpen,
    setSelectedClient,
  } = useAllCoursesPage();

  if (isLoading) {
    return <Loading />;
  }

  const CustomToggle = React.forwardRef(
    ({ children, onClick }, ref) => (
      <Button
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        endIcon={<ArrowDropDownIcon />}
        className={classes.dropdownButton}
        variant="outlined"
      >
        {children}
      </Button>
    ),
  );

  return (
    <Container className={classes.main}>
      <h4
        style={{
          textAlign: 'center',
          marginBottom: '1rem',
        }}
      >
        {course?.nume}
      </h4>
      <div className={classes.form}>
        <h5
        // style={{
        //   textAlign: 'center',
        // }}
        >
          Client{' '}
        </h5>
        {isCreateClientOpen ? (
          <ClientForm
            onCancel={() => setIsCreateClientOpen(false)}
            onSave={(client) => {
              setSelectedClient(client);
              setIsCreateClientOpen(false);
            }}
          />
        ) : (
          <div className={classes.row}>
            <Dropdown className={classes.dropdownButton}>
              <Dropdown.Toggle as={CustomToggle}>
                {selectedClient
                  ? `${selectedClient?.nume}`
                  : 'Selecteaza client'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {clients?.map((item) => (
                  <Dropdown.Item
                    as="button"
                    key={item.id}
                    className={classes.dropdownItem}
                  >
                    <Button
                      onClick={() => {
                        setSelectedClient(item);
                      }}
                      style={{ width: '100%' }}
                    >
                      {item?.nume}
                    </Button>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={() => setIsCreateClientOpen(true)}
            >
              Adauga client
            </Button>
          </div>
        )}
      </div>
      <Row
        //md={2}
        style={{
          alignSelf: 'center',
          marginTop: '2rem',
          marginBottom: '2rem',
        }}
      >
        <Link
          style={{ textDecoration: 'none' }}
          to={`/cursuri/${cursId}/inscriere`}
        >
          <Button
            variant="contained"
            style={{
              alignSelf: 'center',
            }}
            disabled={!selectedClient}
          >
            Adauga Cursant
          </Button>
        </Link>
      </Row>
    </Container>
  );
};

export default CourseDetailsPage;
