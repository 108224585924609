import React from 'react'
import { Row, Spinner } from 'react-bootstrap'

const Loading = (props) => {
    return (
        <Row className="justify-content-md-center">
            <Spinner animation="border" role="status" size='lg' style={{marginTop: 48}}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </Row>
    )
}

export default Loading