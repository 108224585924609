import classes from './Course.module.css';
import { Card, Row, Image, Col } from 'react-bootstrap';
import courseImage from '../../Theme/Assets/placeholder-course-image.jpeg';

const CourseCard = ({ course, onClick }) => {
  //console.log('course', course);
  return (
    <Card
      style={{
        padding: '0.7rem',
        border: '0.5px solid #ccc',
      }}
      onClick={() => {
        onClick(course);
      }}
    >
      <Row style={{ alignItems: 'center' }}>
        <Col style={{ justifyContent: 'center' }}>
          <h4 style={{ textDecoration: 'none' }}>
            {course.nume}
          </h4>
          {/* <h5>Perioada: {coursePeriod}</h5>
          <h5>Locatie: {courseLocation}</h5> */}
        </Col>
      </Row>
    </Card>
  );
};

export default CourseCard;
