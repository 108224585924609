import {
  TextField,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
} from '@mui/material';

import { useInstitutionForm } from './useInstitutionForm';

import classes from './InstitutionForm.module.css';

const InstitutionForm = ({ onSave, onCancel }) => {
  const {
    emailValue,
    emailChangeHandler,
    emailBlurHandler,
    emailHasError,
    emailHelperText,
    institutionNameValue,
    institutionNameHasError,
    institutionNameChangeHandler,
    institutionNameBlurHandler,
    institutionDescriptionValue,
    institutionDescriptionHasError,
    institutionDescriptionChangeHandler,
    institutionDescriptionBlurHandler,
    addressValue,
    addressHasError,
    addressChangeHandler,
    addressBlurHandler,
    phoneValue,
    phoneHasError,
    phoneChangeHandler,
    phoneBlurHandler,
    ibanValue,
    ibanHasError,
    ibanChangeHandler,
    ibanBlurHandler,
    fiscalCodeValue,
    fiscalCodeHasError,
    fiscalCodeChangeHandler,
    fiscalCodeBlurHandler,
    bankValue,
    bankHasError,
    bankChangeHandler,
    bankBlurHandler,
    legalRepresentativeValue,
    legalRepresentativeHasError,
    legalRepresentativeChangeHandler,
    legalRepresentativeBlurHandler,
    formIsValid,
    submitHandler,
  } = useInstitutionForm(onSave);

  return (
    <Box component="form" sx={{ mt: 1 }}>
      <div className={classes.name}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="name"
          label="Numele institutiei"
          type="name"
          id="name"
          autoComplete="name"
          value={institutionNameValue}
          onChange={institutionNameChangeHandler}
          onBlur={institutionNameBlurHandler}
          error={institutionNameHasError}
        />
        <div className={classes.divider} />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Adresa de Email a Institutiei"
          name="email"
          autoComplete="email"
          value={emailValue}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          error={emailHasError}
          helperText={emailHelperText}
        />
        <div className={classes.divider} />
        <TextField
          margin="normal"
          fullWidth
          id="fiscalCode"
          label="Codul Fiscal"
          name="fiscalCode"
          autoComplete="fiscalCode"
          value={fiscalCodeValue}
          onChange={fiscalCodeChangeHandler}
          onBlur={fiscalCodeBlurHandler}
          error={fiscalCodeHasError}
        />
      </div>

      <div className={classes.name}>
        <TextField
          margin="normal"
          fullWidth
          name="iban"
          label="IBAN Institutie"
          id="iban"
          autoComplete="iban"
          value={ibanValue}
          onChange={ibanChangeHandler}
          onBlur={ibanBlurHandler}
          error={ibanHasError}
        />
        <div className={classes.divider} />
        <TextField
          margin="normal"
          fullWidth
          id="bank"
          label="Banca Institutie"
          name="bank"
          autoComplete="bank"
          value={bankValue}
          onChange={bankChangeHandler}
          onBlur={bankBlurHandler}
          error={bankHasError}
        />
        <div className={classes.divider} />
        <TextField
          margin="normal"
          fullWidth
          id="phone"
          label="Nr. de Telefon"
          name="phone"
          autoComplete="phone"
          value={phoneValue}
          onChange={phoneChangeHandler}
          onBlur={phoneBlurHandler}
          error={phoneHasError}
        />
      </div>
      <div className={classes.name}>
        <TextField
          margin="normal"
          fullWidth
          name="legalRepresentative"
          label="Reprezentant Legal"
          id="legalRepresentative"
          autoComplete="legalRepresentative"
          value={legalRepresentativeValue}
          onChange={legalRepresentativeChangeHandler}
          onBlur={legalRepresentativeBlurHandler}
          error={legalRepresentativeHasError}
        />
        <div className={classes.divider} />
        <TextField
          margin="normal"
          fullWidth
          id="address"
          label="Adresa"
          name="address"
          autoComplete="address"
          value={addressValue}
          onChange={addressChangeHandler}
          onBlur={addressBlurHandler}
          error={addressHasError}
        />
        <div className={classes.divider} />
        <TextField
          margin="normal"
          fullWidth
          id="description"
          label="Descriere"
          name="description"
          autoComplete="description"
          value={institutionDescriptionValue}
          onChange={institutionDescriptionChangeHandler}
          onBlur={institutionDescriptionBlurHandler}
          error={institutionDescriptionHasError}
        />
      </div>

      <Button
        variant="contained"
        //disabled={!formIsValid}
        sx={{ mt: 3, mb: 2 }}
        onClick={() => submitHandler()}
      >
        Salveaza Institutie
      </Button>
      <Button
        variant="outlined"
        sx={{ mt: 3, mb: 2 }}
        style={{ marginLeft: 10 }}
        onClick={onCancel}
      >
        Anuleaza
      </Button>
    </Box>
  );
};

export default InstitutionForm;
